import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

const LiveStream = () => {
  const videoRef = useRef(null);
  const socket = useRef(null);
  const transport = useRef(null);
  const [isLive, setIsLive] = useState(true); // État pour savoir si l'utilisateur est en direct

  useEffect(() => {
    // Connexion au serveur
    socket.current = io("http://localhost:3000"); // Remplacez par l'URL correcte de votre backend

    // Capturer la vidéo locale
    const startVideoStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      videoRef.current.srcObject = stream;

      // Initialiser la connexion WebRTC avec MediaSoup
      socket.current.emit("createTransport", async (data) => {
        const { id, iceParameters, iceCandidates, dtlsParameters } = data;

        transport.current = new RTCPeerConnection({
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        });

        // Ajouter des pistes vidéo/audio au transport WebRTC
        stream.getTracks().forEach((track) => {
          transport.current.addTrack(track, stream);
        });

        // Écouter les candidats ICE et les envoyer au serveur
        transport.current.onicecandidate = (event) => {
          if (event.candidate) {
            socket.current.emit("iceCandidate", event.candidate);
          }
        };

        // Mettre à jour les paramètres DTLS et ICE
        await transport.current.setRemoteDescription({
          type: "offer",
          sdp: iceParameters.sdp,
        });

        const answer = await transport.current.createAnswer();
        await transport.current.setLocalDescription(answer);

        // Envoyer la réponse SDP au serveur
        socket.current.emit("connectTransport", {
          dtlsParameters: transport.current.localDescription.sdp,
        });

        // Recevoir la confirmation du serveur que le transport est prêt
        socket.current.on("produce", ({ id }) => {
          console.log("Le transport WebRTC est prêt avec id:", id);
        });
      });
    };

    startVideoStream();

    return () => {
      stopLiveStream(); // Arrêter le stream quand le composant se démonte
    };
  }, []);

  // Fonction pour arrêter le flux vidéo et la diffusion
  const stopLiveStream = () => {
    if (transport.current) {
      // Fermer le transport WebRTC
      transport.current.close();
      transport.current = null;
    }

    // Stopper le flux vidéo
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }

    // Informer le serveur que le live est terminé
    socket.current.emit("stopLive");
    setIsLive(false); // Mettre à jour l'état en hors ligne
  };

  return (
    <div>
      <h1>{isLive ? "Live Stream" : "Stream Stopped"}</h1>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{ width: "100%" }}
      />

      {/* Bouton "Stop Live" au centre en bas */}
      {isLive && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            onClick={stopLiveStream}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Stop Live
          </button>
        </div>
      )}
    </div>
  );
};

export default LiveStream;
