// Mentions légales
import React, {useEffect} from "react";
import { Container, Typography, Box } from "@mui/material";
import MyImage from "./ionos.png";

const Terms = () => {
  useEffect(() => {
    document.title = "jfiscan.fr - Mentions légales";
  }, []);
  return (
    <>
      <Container maxWidth="sm" sx={{ textAlign: "left", mt: 4 }}>
        <Typography variant="h6">
          Jeremy FISCAN
          <br />
          61 rue de Lyon
          <br />
          75012 Paris
        </Typography>
        <Typography
          variant="h4"
          style={{
            fontFamily: "'Big Shoulders Display Medium', sans-serif",
            color: "#2128f5",
            textDecoration: "underline",
          }}
        >
          Hébergement :
        </Typography>
        <Typography variant="h6">
          7 Place de la Gare
          <br />
          57200 Sarreguemines
        </Typography>
        <img
          src={MyImage}
          style={{
            width: "20%",
            height: "auto",
            marginTop: "8px",
            marginBottom: "8px",
          }}
        />
        <Typography
          variant="h4"
          style={{
            fontFamily: "'Big Shoulders Display Medium', sans-serif",
            //jaune color: "#ffed00",
            //bleu ciel color: "#00f0ff",
            //vert canva color: "#05ff00",
            color: "#00ff0a",
            textDecoration: "underline",
          }}
        >
          Directeur de la publication :
        </Typography>
        <Typography variant="h6">
          Jeremy FISCAN
          <br />
          jfiscan@outlook.com
        </Typography>
      </Container>
    </>
  );
};

export default Terms;
