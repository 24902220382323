import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Home from "./Home"; // Supposons que vous ayez une page d'accueil
import LiveStream from "./LiveStream.js";
import ContactPage from "./Contact";
import Terms from "./Terms";
import Footer from "./Footer";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/livestream" element={<LiveStream />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
