import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { FaSquareWhatsapp } from "react-icons/fa6";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const ContactPage = () => {
  useEffect(() => {
    document.title = "jfiscan.fr - Contactez-nous";
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("a.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.text();
      if (result.trim() === "ok") {
        alert("Votre message a été envoyé!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else if (result.trim() === "!ok") {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
            color: "#F00000",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            {/* Forcer la grille à 100% */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#ff00bf",
                    },
                  },
                }}
                label="Nom"
                name="name"
                variant="outlined"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#ff7a00",
                    },
                  },
                }}
                label="Email"
                name="email"
                type="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#ffed00",
                    },
                  },
                }}
                label="Sujet"
                name="subject"
                variant="outlined"
                required
                value={formData.subject}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#05ff00",
                    },
                  },
                }}
                label="Message"
                name="message"
                multiline
                rows={4}
                required
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <IconButton
            component="a"
            href="https://wa.me/+33618187248"
            target="_blank"
            aria-label="WhatsApp"
            sx={{
              color: "#25D366",
            }}
          >
            <FaSquareWhatsapp size={36} color="#25D366" />
          </IconButton>

          <IconButton
            component="a"
            href="https://facebook.com"
            target="_blank"
            aria-label="Facebook"
          >
            <FacebookIcon fontSize="large" color="primary" />
          </IconButton>

          <IconButton
            component="a"
            href="https://instagram.com"
            target="_blank"
            aria-label="Instagram"
          >
            <InstagramIcon fontSize="large" color="secondary" />
          </IconButton>

          <IconButton
            component="a"
            href="https://twitter.com"
            target="_blank"
            aria-label="Twitter"
          >
            <TwitterIcon fontSize="large" color="primary" />
          </IconButton>
        </Box>
      </Container>
    </>
  );
};

export default ContactPage;
