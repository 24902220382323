// Home.js
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom"; // Importer Link depuis react-router-dom
import Auth from "./Auth";

function Home() {
  useEffect(() => {
    document.title = "jfiscan.fr - Acceuil";
  }, []);
  return (
    <div>
      <main>
        {/* Contenu principal de la page d'accueil (vide pour l'instant) */}
        <Auth />
      </main>
    </div>
  );
}

export default Home;
